import style from "../style/card.module.css";
import pBlue from "../image/icon_p_blue.svg";
import noImage from "../image/logo_gray_500×500.png";
// import noImage from "../image/logo_500×500.png";

export const Card = ({ prize }) => {
  const isPrizeImage = prize.images.length > 0;

  return (
    <a href={`/detail?id=${prize.id}`} className={style["card"]}>
      <div className={style["image-wrap"]}>
        <img
          src={isPrizeImage ? prize.images[0] : noImage}
          alt="item-image"
        />
        <StatusTag
          newTag={prize.newTag}
          applicationDeadline={prize.applicationDeadline}
        />
      </div>
      <div className={style["desc"]}>
        <p className={style["title"]}>{prize.title}</p>
        <p className={style["detail"]}>{prize.detail}</p>
        <p className={style["period"]}>
          【交換期間】<br />
          {prize.applicationPeriod}
        </p>
        <div className={style["bottom"]}>
          <div className={style["point-wrap"]}>
            <img src={pBlue} alt="p-blue" />
            <span className={style["application-point"]}>
              {prize.applicationPoint.toLocaleString("ja-JP")}
              <span className={style["unit"]}>pt</span>
            </span>
          </div>
          <ClassficationTag
            classfication={prize.classfication}
          />
        </div>
      </div>
    </a>
  );
}

const StatusTag = ({ newTag, applicationDeadline }) => {
  if (applicationDeadline) {
    return <span className={style["deadline-tag"]}>交換期間外</span>;
  }

  if (newTag) {
    return <span className={style["new-tag"]}>NEW</span>;
  }

  return null;
}

const ClassficationTag = ({ classfication }) => {
  if (classfication === 1) {
    return <span className={style["immediate-tag"]}>もれなく</span>;
  }

  if (classfication === 2) {
    return <span className={style["lottery-tag"]}>抽選</span>;
  }

  if (classfication === 3) {
    return <span className={style["donation-tag"]}>寄付</span>;
  }

  if (classfication === 4) {
    return <span className={style["fcfs-tag"]}>先着</span>;
  }

  return null;
}
