import { useState, useEffect } from "react";
import { checkRequired } from "./utility/checkRequired";
import { usePopupStatus } from "../../hooks/usePopupStatus";
import { ConfirmPopup } from "./component/ConfirmPopup";
import { ResultPopup } from "./component/ResultPopup";
import { Header } from "./component/Header";
import { Error } from "./component/Error";
import { Body } from "./component/Body";
import { ButtonArea } from "./component/ButtonArea";
import style from "./style/style.module.css";

/**
 * 事務局問合せ画面
 */
export const Page = () => {

  // 送信データ
  const [formData, setFormData] = useState({
    content: "", // お問い合わせ内容
  });

  // 送信データの更新
  const setValue = (event) => {
    setFormData((formData) => ({
      ...formData,
      [event.target.name]: event.target.value
    }));
  }

  // 必須項目のnameを配列で管理する
  const requiredFields = [
    "content",
  ];

  // 必須項目が全て入力されているか
  const [isRequiredValid, setIsRequiredValid] = useState(false);
  // formDataに変更があった時、必須チェックを実施する
  useEffect(() => {
    setIsRequiredValid(
      checkRequired({ formData, requiredFields })
    );
  }, [formData])

  // 確認モーダルの管理
  const { isOpen: isOpenConfirmPopup, open: openConfirmPopup, close: closeConfirmPopup } = usePopupStatus();
  // 完了モーダルの管理
  const { isOpen: isOpenResultPopup, open: openResultPopup } = usePopupStatus();

  // バリデーション処理
  const [validError, setValidError] = useState({});
  useEffect(() => {
    setValidError({});
  }, [])

  return (
    <div className={style["background"]}>
      <ConfirmPopup
        isOpen={isOpenConfirmPopup}
        formData={formData}
        closeConfirmPopup={closeConfirmPopup}
        openResultPopup={openResultPopup}
        setValidError={setValidError}
      />
      <ResultPopup isOpen={isOpenResultPopup} />
      <Header />
      {validError?.fieldErrorList && <Error fieldErrorList={validError.fieldErrorList} />}
      <Body
        formData={formData}
        setValue={setValue}
        validError={validError}
      />
      <ButtonArea
        isRequiredValid={isRequiredValid}
        openConfirmPopup={openConfirmPopup}
        validError={validError}
      />
    </div>
  );
}
