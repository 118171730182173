import { useNavigate } from "react-router-dom";
import style from "../style/header.module.css";
import arrowIcon from "../image/header_arrow_left.svg";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <header>
      <h1 className={style["page-heading"]}>
        <a
          className={style["arrow-icon"]}
          onClick={() => {
            if (1 < window.history.length) {
              navigate(-1);
            } else {
              navigate("/");
            }
          }}
        >
          <img
            src={arrowIcon}
            alt="arrow"
          />
        </a>
        想定CO&#8322;排出量
      </h1>
    </header>
  );
}