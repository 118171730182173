import { useNavigate } from "react-router-dom";
import style from "../style/style.module.css";
import arrowIcon from "../image/header_arrow_left.svg";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <h1 className={style["page-heading"]}>
          <a
            className={style["arrow-icon"]}
            onClick={() => {
              if (1 < window.history.length) {
                navigate(-1);
              } else {
                navigate("/");
              }
            }}
          >
            <img
              src={arrowIcon}
              alt="arrow"
            />
          </a>
          コードでポイント獲得
        </h1>
      </header>
      <p className={style["guide"]}>
        下記フォームへ必要事項をご入力の上、<br />送信ください。
      </p>
    </>
  );
}
