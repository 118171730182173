import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { PointInfo } from "./component/PointInfo";
import { Tags } from "./component/Tags";
import { List } from "./component/List";
import { filterPrizeListWithTagId } from "./utility/filterPrizeListWithTagId.js";
import { useGetRequest } from "../../hooks/useGetRequest"
import style from "./style/style.module.css";

/**
 * プレゼント一覧画面
 */
export const Page = () => {
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");
  const tagId = searchParams.get("tagId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  const [selectedTagId, setSelectedTagId] = useState(tagId);
  useEffect(() => {
    setSelectedTagId(tagId);
  }, [tagId]);

  const API_URL = "/api/present/list";
  const { data, isLoading, error } = useGetRequest(API_URL, params);

  const viewPrizeList = filterPrizeListWithTagId(data?.prizeList ?? [], tagId);

  if (isLoading || error) {
    return null;
  }

  return (
    <div className={style["sticky-container"]}>
      <div className={style["sticky"]}>
        <Header />
        <PointInfo
          isEntered={data.isEntered ?? 0}
          totalValidPoint={data.totalValidPoint}
          nearestExpirationDate={data.nearestExpirationDate}
          pointsByExpirationDate={data.pointsByExpirationDate}
        />
        <Tags
          tagList={data.tagList ?? []}
          selectedTagId={selectedTagId}
        />
      </div>
      <List
        prizeList={viewPrizeList}
      />
    </div>
  );
}