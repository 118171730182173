import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { List } from "./component/List";
import { groupListByYear } from "./utility/groupListByYear";
import { useGetRequest } from "../../hooks/useGetRequest";
import { useYear } from "./hooks/useYear";
import style from "./style/main.module.css";
import arrowIcon from "./image/arrow_right.svg";

/**
 * ポイント履歴画面
 */
export const Page = () => {
  const [groupedList, setGroupedList] = useState();
  // 年の選択
  const { year, setYear, incrementYear, decrementYear } = useYear();

  // クエリ文字列からroomId,developerIdを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  // 内部APIの実行
  const API_URL = "/api/point/history";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  useEffect(() => {
    const { groupedList, maxYear, minYear } = groupListByYear(data?.fluctuationPointList || []);
    setGroupedList(groupedList);
    setYear({
      selected: maxYear,
      max: maxYear,
      min: minYear
    });
  }, [data]);

  if (isLoading || error) {
    return null;
  }

  // 直近有効期限切れポイント情報の表示フラグ
  const showNearestExpirationInfo
    = data.nearestExpirationDate !== null
    && data.pointsByExpirationDate !== 0
    && data.pointsByExpirationDate !== null;

  return (
    <>
      <Header />
      <main className={style["main"]}>
        <p className={style["total-valid-point-wrap"]}>
          保有ポイント
          <span className={style["total-valid-point"]}>
            {data.totalValidPoint && data.totalValidPoint.toLocaleString("ja-JP")}<span className={style["total-valid-point-unit"]}>pt</span>
          </span>
        </p>
        {
          showNearestExpirationInfo &&
          <p className={style["points-by-expiration-date"]}>
            {data.nearestExpirationDate}末に{data.pointsByExpirationDate.toLocaleString("ja-JP")}pt期限切れ
          </p>
        }
        <Link to="/pointLimit" className={style["link"]}>
          ポイント有効期限を確認する
          <img src={arrowIcon} alt="arrow" className={style["arrow-right"]} />
        </Link>
        <List
          list={groupedList?.[year.selected] || []}
          year={year.selected}
          maxYear={year.max}
          minYear={year.min}
          incrementYear={incrementYear}
          decrementYear={decrementYear}
        />
      </main>
    </>
  );
}