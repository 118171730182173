import { useState } from "react";
import style from "../style/main.module.css";
import arrowIcon from "../image/icon_year_select.svg"
import { firstYear } from "../../../utility/mapping.js";

export const Table = ({
  year,
  energyName,
  energyUnit,
  energyPerformanceData,
  energyTargetData,
  co2PerformanceData
}) => {
  const [isTableOpen, setIsTableOpen] = useState(false);

  const toggleTable = () => {
    setIsTableOpen((isOpen) => {
      return !isOpen;
    });
  }

  return (
    <div className={style["table-wrap"]}>
      <h3
        className={style["table-heading"]}
        onClick={() => {
          toggleTable();
        }}
      >
        詳しく見る
        <img
          src={arrowIcon}
          alt="arrow"
          className={`${style["table-heading-arrow"]} ${isTableOpen && style["open"]}`}
        />
      </h3>
      <div className={`${style["table-content"]} ${isTableOpen && style["open"]}`}>
        <div className={style["row"]}>
          <span>年月</span>
          <span>{energyName}使用量</span>
          <span>想定CO&#8322;排出量</span>
          <span></span>
        </div>
        <List
          year={year}
          firstYear={firstYear}
          energyUnit={energyUnit}
          energyPerformanceData={energyPerformanceData}
          energyTargetData={energyTargetData}
          co2PerformanceData={co2PerformanceData}
        />
      </div>
    </div>
  );
}

const List = ({
  year,
  energyUnit,
  energyPerformanceData,
  energyTargetData,
  co2PerformanceData
}) => {
  const list = [];
  const hasTargetData = energyTargetData.length > 0;

  for (let index = 0; index <= 11; index++) {
    list.push(
      <div
        className={`${style["row"]} ${style["content"]}`}
        key={index}
      >
        <span>
          {index > 8 ? year + 1 : year}
          /
          {index > 8 ? index - 8 : index + 4}
        </span>
        <CellEnergyOutput
          hasTargetData={hasTargetData}
          energyTarget={energyTargetData?.[index]}
          energyPerformance={energyPerformanceData?.[index]}
          energyUnit={energyUnit}
        />
        <Co2Output
          co2Performance={co2PerformanceData?.[index]}
        />
        <span></span>
      </div>
    );
  }

  return <>{list}</>;
}

const CellEnergyOutput = ({
  hasTargetData,
  energyTarget = null,
  energyPerformance = null,
  energyUnit
}) => {
  if (hasTargetData) {
    return (
      <span>
        <span className={style["cell-flex"]}>
          <span className={style["text-performance-label"]}>実績値</span>
          {
            energyPerformance !== null &&
            <span className={style["text-performance"]}>{energyPerformance.toLocaleString("ja-JP")}<span className={style["unit"]}>{energyUnit}</span></span>
          }
        </span>
        <span className={`${style["text-target"]} ${style["cell-flex"]}`}>
          <span className={style["label"]}>標準値</span>
          {
            energyTarget !== null &&
            <span>{energyTarget.toLocaleString("ja-JP")}{energyUnit}</span>
          }
        </span>
      </span>

    );
  }

  return (
    <span>
      {
        energyPerformance !== null &&
        <span className={style["text-performance"]}>{energyPerformance.toLocaleString("ja-JP")}<span className={style["unit"]}>{energyUnit}</span></span>
      }
    </span>
  );
}

const Co2Output = ({ co2Performance = null }) => {
  if (co2Performance === null) {
    return <span></span>;
  }

  return (
    <span className={style["text-output"]}>
      {co2Performance.toLocaleString("ja-JP")}<span className={style["unit"]}>kg</span>
    </span>
  );
}
