export const checkClient = (formData) => {
  const fieldErrorList = [];

  // エントリー氏名（姓）の文字数制限
  if (formData.representativeLastName.length > 64) {
    fieldErrorList.push({
      field: "representativeLastName",
      fieldErrorMessage: "文字数オーバーです。64文字以内で入力してください。"
    });
  }

  // エントリー氏名（名）の文字数制限
  if (formData.representativeFirstName.length > 64) {
    fieldErrorList.push({
      field: "representativeFirstName",
      fieldErrorMessage: "文字数オーバーです。64文字以内で入力してください。"
    });
  }

  // 生年月日の日付が正しいか
  if (!checkDateCorrect(formData.representativeBirthYear, formData.representativeBirthMonth, formData.representativeBirthDay)) {
    fieldErrorList.push(
      {
        field: "representativeBirthdate",
        fieldErrorMessage: "日付が間違っています。"
      },
      {
        field: "representativeBirthYear",
        fieldErrorMessage: null
      },
      {
        field: "representativeBirthMonth",
        fieldErrorMessage: null
      },
      {
        field: "representativeBirthDay",
        fieldErrorMessage: null
      }
    );
  }

  // 生年月日（年）の形式判定
  const representativeBirthYearPattarn = /^\d{4}$/;
  if (!representativeBirthYearPattarn.test(formData.representativeBirthYear) || formData.representativeBirthYear < 1000 || 9999 < formData.representativeBirthYear) {
    fieldErrorList.push({
      field: "representativeBirthYear",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 生年月日（月）の形式判定
  const representativeBirthMonthPattarn = /^\d{1,2}$/;
  if (!representativeBirthMonthPattarn.test(formData.representativeBirthMonth) || formData.representativeBirthMonth < 1 || 12 < formData.representativeBirthMonth) {
    fieldErrorList.push({
      field: "representativeBirthMonth",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 生年月日（日）の形式判定
  const representativeBirthDayPattarn = /^\d{1,2}$/;
  if (!representativeBirthDayPattarn.test(formData.representativeBirthDay) || formData.representativeBirthDay < 1 || 31 < formData.representativeBirthDay) {
    fieldErrorList.push({
      field: "representativeBirthDay",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // お届け先で下記の住所へ送るを選択されている場合
  if (formData.isDeliveryInfoEqualToEntryInfo === false || formData.isDeliveryInfoEqualToEntryInfo === "false") {

    // 配送先郵便番号の半角数字判定
    const deliveryZipPattarn = /^\d{7}$/;
    if (!deliveryZipPattarn.test(formData.deliveryZip)) {
      fieldErrorList.push({
        field: "deliveryZip",
        fieldErrorMessage: "形式が異なります。"
      });
    }

    // 配送先住所の文字数制限
    if (formData.deliveryAddress.length > 500) {
      fieldErrorList.push({
        field: "deliveryAddress",
        fieldErrorMessage: "文字数オーバーです。"
      });
    }

    // 配送先宛名の文字数制限
    if (formData.deliveryName.length > 128) {
      fieldErrorList.push({
        field: "deliveryName",
        fieldErrorMessage: "文字数オーバーです。128文字以内で入力してください。"
      });
    }
  }

  // 配送先電話番号の半角数字判定
  const deliveryTellPattarn = /^\d{10}$|^\d{11}$/;
  if (!deliveryTellPattarn.test(formData.deliveryTell)) {
    fieldErrorList.push({
      field: "deliveryTell",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 入居予定日の日付が存在するものか
  if (!checkDateCorrect(formData.usageFromYear, formData.usageFromMonth, formData.usageFromDay)) {
    fieldErrorList.push(
      {
        field: "usageFromDate",
        fieldErrorMessage: "日付が間違っています。"
      },
      {
        field: "usageFromYear",
        fieldErrorMessage: null
      },
      {
        field: "usageFromMonth",
        fieldErrorMessage: null
      },
      {
        field: "usageFromDay",
        fieldErrorMessage: null
      }
    );
  }

  // 入居予定日（年）の形式判定
  const usageFromYearPattarn = /^\d{4}$/;
  if (!usageFromYearPattarn.test(formData.usageFromYear) || formData.usageFromYear < 1000 || 9999 < formData.usageFromYear) {
    fieldErrorList.push({
      field: "usageFromYear",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 入居予定日（月）の形式判定
  const usageFromMonthPattarn = /^\d{1,2}$/;
  if (!usageFromMonthPattarn.test(formData.usageFromMonth) || formData.usageFromMonth < 1 || 12 < formData.usageFromMonth) {
    fieldErrorList.push({
      field: "usageFromMonth",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 入居予定日（日）の形式判定
  const usageFromDayPattarn = /^\d{1,2}$/;
  if (!usageFromDayPattarn.test(formData.usageFromDay) || formData.usageFromDay < 1 || 31 < formData.usageFromDay) {
    fieldErrorList.push({
      field: "usageFromDay",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 入力値に問題がある場合、objectを返す
  if (fieldErrorList.length > 0) {
    return fieldErrorList;
  }

  // 入力値に問題がない場合、trueを返す
  return true;
}

const checkDateCorrect = (y, m, d) => {
  const date = new Date(y, m - 1, d);
  const month = date.getMonth() + 1;
  if (m == month) {
    return true;
  }
  return false;
}
