import {
  InputTitle,
  InputTextArea,
} from "./FormParts";
import style from "../style/style.module.css";

export const Body = ({
  formData,
  setValue,
  validError
}) => {
  return (
    <div className={style["section-body"]}>
      <p className={style["text-center"]}>お問い合わせ内容を記入してください。</p>
      <InputTitle
        required
      />
      <div className={style["input-content-wrap"]}>
        <InputTextArea
          defaultValue={formData?.content}
          name="content"
          setValue={setValue}
          fullWidth
          rows="10"
          validError={validError}
          maxlength={1000}
        />
      </div>
      <p className={style["text-center"]}>返答まで３営業日程度かかる場合があります。</p>
    </div>
  );
}
