import style from "../style/formParts.module.css";

/**
 * input title
 */
export const InputTitle = ({ required = false }) => {
  return (
    <h3 className={style["input-title"]}>
      {
        required && <RequiredIcon />
      }
    </h3>
  );
}

/**
 * required icon
 */
export const RequiredIcon = () => {
  return (
    <span className={style["required-icon"]}>
      必須
    </span>
  );
}

/**
 * textarea
 */
export const InputTextArea = ({
  placeholder,
  defaultValue,
  name,
  setValue,
  rows,
  validError,
  maxlength = null
}) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);
  return (
    <textarea
      className={`${style["input-text"]} ${hasError && style["error"]}`}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onInput={setValue}
      rows={rows}
      wrap="soft"
      maxLength={maxlength}
    />
  );
}
