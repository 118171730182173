import style from "../style/style.module.css";

export const Error = ({ fieldErrorList }) => {
  return (
    <div className={style["error-section"]}>
      <div className={style["error-wrap"]}>
        {fieldErrorList.length > 0 && <p>入力内容をご確認ください</p>}
      </div>
    </div>
  );
}
