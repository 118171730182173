import style from "../style/activity.module.css";
import overIcon from "../image/icon_over.svg";

export const Activity = ({
  month,
  co2Performance,
  co2Reduction,
  point,
  evaluationStatusIcon
}) => {

  if (month === null) {
    return null;
  }

  return (
    <div className={style["activity"]}>
      <p className={style["activity-result-title"]}>{month}月の活動結果</p>
      <div className={style["activity-result-wrap"]}>
        <div className={style["activity-result-item"]}>
          <p className={style["activity-result-item-title"]}>想定CO&#8322;排出量</p>
          <p className={style["activity-result-item-data"]}>
            <span>
              {co2Performance.toLocaleString("ja-JP")}
              <span className={style["unit"]}>kg</span>
            </span>
          </p>
        </div>
        <div className={style["activity-result-item"]}>
          <p className={style["activity-result-item-title"]}>想定CO&#8322;削減量</p>
          <p className={style["activity-result-item-data"]}>
            {
              co2Reduction > 0 &&
              <img
                src={overIcon}
                className={style["activity-result-item-icon"]}
                alt="icon"
              />
            }
            <span>
              {Math.abs(co2Reduction).toLocaleString("ja-JP")}
              <span className={style["unit"]}>kg</span>
            </span>
          </p>
        </div>
        <div className={style["activity-result-item"]}>
          <p className={style["activity-result-item-title"]}>獲得ポイント</p>
          <p className={`${style["activity-result-item-data"]} ${style["point"]}`}>
            <img src={evaluationStatusIcon} className={style["activity-result-item-icon"]} alt="icon" />
            <span>
              {point.toLocaleString("ja-JP")}
              <span className={style["unit"]}>pt</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
