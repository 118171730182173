import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";

export const PrizeImageSlide = ({ images }) => {
  return (
    <div style={{
      // padding: "10px", overflow: "visible"
    }}>
      <Swiper
        slidesPerView={1.1}
        spaceBetween={5}
        autoHeight={true}
        centeredSlides={true}
      >
        {
          images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="prize-image" />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
}

