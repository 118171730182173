import { useLocation } from "react-router-dom";
import style from "./style/error.module.css";

/**
 * 共通エラーページ
 * useNavigateのstateに{errorMessage:"hoge",errorCode:"hoge"}の形式で渡される
 */
export const Page = () => {
  const location = useLocation();

  const {
    errorCode = "default error code",
    errorMessage = "default error message"
  } = location.state ?? {};


  return (
    <div className={style["error"]}>
      <p className={style["sorry"]}>SORRY...</p>
      <div className={style["error-wrap"]}>
        <p className={style["error-message"]}>{errorMessage}</p>
        <p className={style["error-message"]}>（エラーコード：{errorCode}）</p>
      </div>
      <div className={style["info-wrap"]}>
        <p className={style["info"]}>
          解消しない場合は、<br />
          事務局へお問い合わせください。<br />
          その際、エラーコードをお知らせください。
        </p>
      </div>
    </div>
  );
}