export const convertFieldErrorList = (fieldErrorList) => {
  console.log(fieldErrorList);
  return fieldErrorList.map(fieldError => {
    const { field, fieldErrorMessage } = fieldError;
    if (!mappingValidErrorMessage[field]) {
      return {
        field,
        fieldErrorMessage: null
      };
    }
    const target = mappingValidErrorMessage[field].find(item => item.message === fieldErrorMessage);
    return {
      field,
      fieldErrorMessage: target?.displayMessage ?? null
    };
  });
}

const mappingValidErrorMessage = {
  representativeLastName: [
    {
      message: "必須項目になります。",
      displayMessage: "エントリー氏名（姓）を入力してください。"
    },
    {
      message: "文字数オーバーです。64文字以内で入力してください。",
      displayMessage: "エントリー氏名（姓）を短く入力してください。"
    }
  ],
  representativeFirstName: [
    {
      message: "必須項目になります。",
      displayMessage: "エントリー氏名（名）を入力してください。"
    },
    {
      message: "文字数オーバーです。64文字以内で入力してください。",
      displayMessage: "エントリー氏名（名）を短く入力してください。"
    }
  ],
  representativeBirthYear: [{
    message: "形式が異なります。",
    displayMessage: "生年月日（年）を正しく入力してください。"
  }],
  representativeBirthMonth: [{
    message: "形式が異なります。",
    displayMessage: "生年月日（月）を正しく入力してください。"
  }],
  representativeBirthDay: [{
    message: "形式が異なります。",
    displayMessage: "生年月日（日）を正しく入力してください。"
  }],
  representativeBirthdate: [{
    message: "日付が間違っています。",
    displayMessage: "生年月日を正しく入力してください。"
  }],
  deliveryName: [
    {
      message: "形式が異なります。",
      displayMessage: "お届け先の宛名を正しく入力してください。"
    },
    {
      message: "文字数オーバーです。128文字以内で入力してください。",
      displayMessage: "お届け先の宛名を短く入力してください。"
    }
  ],
  deliveryZip: [
    {
      message: "必須項目になります。",
      displayMessage: "お届け先の郵便番号を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "お届け先の郵便番号を正しく入力してください。"
    }
  ],
  deliveryAddress: [
    {
      message: "必須項目になります。",
      displayMessage: "お届け先の住所を入力してください。"
    },
    {
      message: "文字数オーバーです。",
      displayMessage: "お届け先の住所を短く入力してください。"
    }
  ],
  deliveryTell: [
    {
      message: "必須項目になります。",
      displayMessage: "お届け先の電話番号を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "お届け先の電話番号を正しく入力してください。"
    }
  ],
  energyvenderId: [{
    message: "必須項目になります。",
    displayMessage: "予定契約先をお選びください。"
  }],
  usageFromYear: [
    {
      message: "必須項目になります。",
      displayMessage: "入居予定日（年）を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "入居予定日（年）を正しく入力してください。"
    }
  ],
  usageFromMonth: [
    {
      message: "必須項目になります。",
      displayMessage: "入居予定日（月）を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "入居予定日（月）を正しく入力してください。"
    }
  ],
  usageFromDay: [
    {
      message: "必須項目になります。",
      displayMessage: "入居予定日（日）を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "入居予定日（日）を正しく入力してください。"
    }
  ],
  usageFromDate: [{
    message: "日付が間違っています。",
    displayMessage: "入居予定日を正しく入力してください。"
  }]
}

