import { useState } from "react";

export const useCounter = (defaultCount) => {
  const [count, setCount] = useState(defaultCount);
  const decrement = () => {
    setCount((count) => {
      return count - 1;
    });
  }
  const increment = () => {
    setCount((count) => {
      return count + 1;
    });
  }

  return { count, decrement, increment };
}