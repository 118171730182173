import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { PrizeDescription } from "./component/PrizeDescription";
import { PrizeImageSlide } from "./component/PrizeImageSlide";
import { ButtonArea } from "./component/ButtonArea";
import { Applied } from "./component/Applied";
import { ConfirmPopup } from "./component/ConfirmPopup";
import { ResultPopup } from "./component/ResultPopup";
import { useGetRequest } from "../../hooks/useGetRequest";
import { usePopupStatus } from "../../hooks/usePopupStatus";

/**
 * プレゼント詳細画面
 */
export const Page = () => {
  // 確認モーダルの管理
  const { isOpen: isOpenConfirmPopup, open: openConfirmPopup, close: closeConfirmPopup } = usePopupStatus();
  // 完了モーダルの管理
  const { isOpen: isOpenResultPopup, open: openResultPopup } = usePopupStatus();

  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");
  const prizeId = searchParams.get("id");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }
  params.id = prizeId;

  const API_URL = "/api/present";
  const { data, isLoading, error } = useGetRequest(API_URL, params);

  const [formData, setFormData] = useState({});
  useEffect(() => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        id: data?.id ?? null
      }
    });
  }, [data]);

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <ConfirmPopup
        isOpen={isOpenConfirmPopup}
        formData={formData}
        closeConfirmPopup={closeConfirmPopup}
        openResultPopup={openResultPopup}
      />
      <ResultPopup isOpen={isOpenResultPopup} />
      <Header />
      <PrizeImageSlide
        images={data.images}
      />
      <PrizeDescription
        classfication={data.classfication}
        newTag={data.newTag}
        applicationDeadline={data.applicationDeadline}
        title={data.title}
        detail={data.detail}
        applicationPoint={data.applicationPoint}
        applicationPeriod={data.applicationPeriod}
        applicationTerms={data.applicationTerms}
        minnerDitail={data.minnerDitail}
        note={data.note}
      />
      <ButtonArea
        totalValidPoint={data.totalValidPoint ?? 0}
        applicationPoint={data.applicationPoint}
        applicableUnit={data.applicableUnit ?? Infinity}
        appliedUnit={data.appliedUnit}
        applicationDeadline={data.applicationDeadline}
        openConfirmPopup={openConfirmPopup}
        setFormData={setFormData}
      />
      <Applied
        appliedUnit={data.appliedUnit}
      />
    </>
  );
}