import style from "../style/pointInfo.module.css";

export const PointInfo = ({
  isEntered,
  totalValidPoint,
  nearestExpirationDate,
  pointsByExpirationDate
}) => {
  // 直近有効期限切れポイント情報の表示フラグ
  const showNearestExpirationInfo
    = isEntered !== 0
    && nearestExpirationDate !== null
    && pointsByExpirationDate !== 0
    && pointsByExpirationDate !== null;

  return (
    <>
      <p className={style["total-valid-point-wrap"]}>
        保有ポイント
        <span className={style["total-valid-point"]}>
          {
            isEntered === 0
              ? 0
              : totalValidPoint.toLocaleString("ja-JP")
          }
          <span className={style["total-valid-point-unit"]}>pt</span>
        </span>
      </p>
      <p className={style["points-by-expiration-date"]}>
        {
          showNearestExpirationInfo &&
          `${nearestExpirationDate}末に${pointsByExpirationDate.toLocaleString("ja-JP")}pt期限切れ`
        }
      </p>
    </>
  );
}