import { usePostRequest } from "../../../hooks/usePostRequest";
import { checkClient } from "../utility/checkClient";
import style from "../style/confirmPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ConfirmPopup = ({
  isOpen,
  formData,
  closeConfirmPopup,
  openResultPopup,
  setValidError
}) => {
  const API_URL = "/api/secretariat/contact";
  const { post, isLoading } = usePostRequest({
    url: API_URL,
    postData: formData,
    onSuccess() {
      closeConfirmPopup();
      openResultPopup();
    },
    onValidError(data) {
      closeConfirmPopup();
      setValidError(data);
    }
  });

  const onClick = () => {
    // post処理中にクリックされた場合にはイベントをキャンセルする
    if (isLoading) return;
    // フロント側でバリデーションを行う
    const checkClientResult = checkClient(formData);
    // フロント側のバリデーションでエラーがあった場合
    if (checkClientResult !== true) {
      setValidError({
        errorCode: "VALID_ERROR_FRONT",
        errorMessage: "VALID_ERROR_FRONT",
        fieldErrorList: checkClientResult
      });
      closeConfirmPopup();
      return null;
    }
    // フロント側のバリデーションでエラーがなかった場合
    post();
  }

  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <img
          src={closeModal}
          alt="close modal"
          className={style["close"]}
          onClick={() => {
            closeConfirmPopup()
          }}
        />
        <p className={style["text"]}>
          くらしのサス活事務局へ<br />
          ご入力の情報を送信します。<br />
          <br />
          送信後は、ご入力内容を連絡履歴にて<br />
          ご確認いただけます。<br />
          <br />
          よろしければ<br />
          OKボタンを押してください。<br />
        </p>
        <a className={style["button"]} onClick={onClick}>OK</a>
      </div>
    </div >
  );
}
