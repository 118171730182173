/**
 * 賞品リストをタグIDリストでフィルタする(or)
 * @param {array} prizeList - フィルタされる賞品リスト
 * @param {array} tagId - フィルタするタグIDリスト　例）[{id: 2}{id: 3}]
 * @return {array} フィルタ後の賞品リストを返す
 */
export const filterPrizeListWithTagList = (prizeList, tagList) => {
  const filteredPrizeList = prizeList.filter((prize) => (

    tagList.some((tag) => (
      prize.tagIdList.some((prizeTag) => ( // 指定のタグIDを持つ賞品
        prizeTag.id === tag.id
      )) && prize.applicationDeadline === false // かつ、締切ではない賞品
    ))

  ));

  return filteredPrizeList;
}