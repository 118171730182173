import style from "../style/style.module.css";

/**
 * 見出し
 * @param {string} title タイトル
 * @returns 見出し
 */
export const Section = ({title, children}) => {
  return (
    <>
      <SectionHeading title={title} />
      <SectionBody>
        {children}
      </SectionBody>
    </>
  );
}

/**
 * 見出し
 * @param {string} title タイトル
 * @returns 見出し
 */
export const SectionHeading = ({title}) => {
  return (
    <h2 className={style["section-heading"]}>
      {title}
    </h2>
  );
}

/**
 * コンテンツ
 * @returns コンテンツ
 */
export const SectionBody = ({children}) => {
  return (
    <div className={style["section-body"]}>
      {children}
    </div>
  );
}
