import style from "../style/list.module.css";

export const List = ({
  list,
}) => {
  return (
    <div className={style["list-area"]}>
      <div className={style["column-wrap"]}>
        <span className={style["column-text"]}>有効期限</span>
        <span className={style["column-text"]}>ポイント</span>
      </div>
      {
        list.map((item, index) => (
          <div key={index} className={`${style["list"]}`}>
            <div className={style["list-left"]}>
              <span className={style["list-limit"]}>{item.expirationDate}</span>
              <span className={style["list-limit-suffix"]}>末</span>
            </div>
            <div className={style["list-right"]}>
              <span className={style["list-point"]}>
                -{Number(item.point).toLocaleString("ja-JP")}
              </span>
              <span className={style["list-point-unit"]}>pt</span>
            </div>
          </div>
        ))
      }
    </div>
  );
}
