import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "../style/resultPopup.module.css";
import completeIcon from "../image/complete.svg";

export const ResultPopup = ({ isOpen }) => {

  const navigate = useNavigate();

  const [className, setClassName] = useState(`${style["popup"]}`);

  useEffect(() => {
    if (isOpen) {
      setClassName(`${style["popup"]} ${style["open"]}`);

      const timer = setTimeout(() => {
        navigate("/");
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setClassName(`${style["popup"]}`);
    }
  }, [isOpen]);

  return (
    <div className={className}>
      <div className={style["popup-inner"]}>
        <img src={completeIcon} alt="complete icon" className={style["complete"]} />
        <p className={style["text"]}>
          くらしのサス活事務局へ<br />
          ご入力の情報を送信いたしました。
        </p>
      </div>
    </div>
  );
}