import style from "../style/formParts.module.css";

/**
 * input title
 * @returns input title
 */
export const InputTitle = ({ required = false }) => {
  return (
    <h3 className={style["input-title"]}>
      {
        required && <RequiredIcon />
      }
    </h3>
  );
}

/**
 * required icon
 * @returns required icon
 */
export const RequiredIcon = () => {
  return (
    <span className={style["required-icon"]}>
      必須
    </span>
  );
}

/**
 * input text
 * @returns input text
 */
export const InputText = ({
  placeholder,
  defaultValue,
  name,
  setValue,
  validError,
  maxlength = null
}) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);
  return (
    <input
      type="text"
      className={`${style["input-text"]} ${hasError && style["error"]}`}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      maxLength={maxlength}
      onInput={setValue}
    />
  );
}
