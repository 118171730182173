/**
 * APIから取得してきたポイント履歴リストを年ごとにグルーピングして返す
 * @param {array} List - APIから取得してきたポイント履歴リスト 
 * @return {Object} 
 * @property {array} groupedList - 年ごとにグルーピングされたポイント履歴リスト
 * @property {number} maxYear - ポイント履歴の存在する最大年
 * @property {number} minYear - ポイント履歴の存在する最小年
 */
export const groupListByYear = (List) => {
  const groupedList = {};
  const years = [];

  List.forEach(item => {
    const year = item.year;
    if (!groupedList[year]) {
      groupedList[year] = [];
      years.push(year);
    }
    groupedList[year].push(item);
  });

  return {
    groupedList,
    maxYear: Math.max(...years),
    minYear: Math.min(...years)
  }
}