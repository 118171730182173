import { useState } from "react";

export const useYear = () => {
  const [year, setYear] = useState({
    selected: 2023,
    max: 9999,
    min: 0
  });

  const incrementYear = () => {
    setYear((year) => {
      return ({
        ...year,
        selected: year.selected + 1
      });
    });
  };

  const decrementYear = () => {
    setYear((year) => {
      return ({
        ...year,
        selected: year.selected - 1
      });
    });
  };

  return ({
    year,
    setYear,
    incrementYear,
    decrementYear
  });
}