import graphLegendCo2_2024 from "../assets/image/graph_legend_co2_2024.svg";
import graphLegendCo2_2025 from "../assets/image/graph_legend_co2_2025.svg";
import graphLegendElectric from "../assets/image/graph_legend_electric.svg";
import graphLegendGas from "../assets/image/graph_legend_gas.svg";
import graphLegendElectric_2024 from "../assets/image/graph_legend_electric_2024.svg";
import graphLegendGas_2024 from "../assets/image/graph_legend_gas_2024.svg";
import evaluationA1 from "../assets/image/icon_point_evaluation_A1.svg";
import evaluationA2 from "../assets/image/icon_point_evaluation_A2.svg";
import evaluationA3 from "../assets/image/icon_point_evaluation_A3.svg";
import evaluationB1 from "../assets/image/icon_point_evaluation_B1.svg";
import evaluationB2 from "../assets/image/icon_point_evaluation_B2.svg";
import evaluationB3 from "../assets/image/icon_point_evaluation_B3.svg";
import evaluationB4 from "../assets/image/icon_point_evaluation_B4.svg";

// サービス開始初年度
export const firstYear = 2024;

export const co2GraphLegend = {
  "default": graphLegendCo2_2024,
  [firstYear]: graphLegendCo2_2024,
  "2025": graphLegendCo2_2025
}

export const graphLegend = {
  gas: {
    "default": graphLegendGas,
    "noTarget": graphLegendGas_2024
  },
  electric: {
    "default": graphLegendElectric,
    "noTarget": graphLegendElectric_2024
  }
}

export const evaluationIcons = {
  "A1": evaluationA1,
  "A2": evaluationA2,
  "A3": evaluationA3,
  "B1": evaluationB1,
  "B2": evaluationB2,
  "B3": evaluationB3,
  "B4": evaluationB4
}
