import { evaluationIcons } from "../../../utility/mapping.js";

// 内部APIで取得した電気データ、ガスデータ、ポイントデータ、CO2データを扱いやすいように変換する。
export const convertEnergyData = ({ electricalUsageList, gasUsageList, earnPointList, co2UsageList }) => {
  const {
    electricPerformance,
    electricTarget,
    electricCo2Performance,
    electricYears
  } = convertElectricalUsageList(electricalUsageList ?? []);
  const {
    gasPerformance,
    gasTarget,
    gasCo2Performance,
    gasYears
  } = convertGasUsageList(gasUsageList ?? []);
  const {
    pointList,
    evaluationStatusList,
    evaluationStatusIconList,
    pointListYears
  } = convertEarnPointList(earnPointList ?? []);
  const { co2Target, co2Years } = convertCo2UsageList(co2UsageList ?? []);

  // 電気ガス合計CO2実績値データ「co2Performance」の作成
  const co2Performance = createCo2Performance(electricCo2Performance, gasCo2Performance);

  const energyData = {
    electricPerformance,
    electricTarget,
    electricCo2Performance,
    gasPerformance,
    gasTarget,
    gasCo2Performance,
    pointList,
    evaluationStatusList,
    evaluationStatusIconList,
    co2Target,
    co2Performance
  }

  // 各種データが存在する年度
  const years = new Set([...electricYears, ...gasYears, ...pointListYears, ...co2Years]);

  return {
    energyData,
    initialYear: getInitialYear(co2Performance),
    maxYear: Math.max(...years),
    minYear: Math.min(...years),
    nearestActivity: getNearestActivity(energyData)
  };
}

const getIndexOfMonth = (month) => {
  const indexOfMonth = (month >= 4 && month <= 12) ? month - 4 : month + 8;
  return indexOfMonth;
}

const toFiscalYear = (year, month) => {
  const fiscalYear = month >= 1 && month <= 3 ? year - 1 : year;
  return fiscalYear;
}

const assignData = (data, fiscalYear, indexOfMonth, value) => {
  if (value === null) return;
  if (data[fiscalYear]) {
    data[fiscalYear][indexOfMonth] = value;
    return;
  }
  data[fiscalYear] = Array(12).fill(null);
  data[fiscalYear][indexOfMonth] = value;
}

const createCo2Performance = (electricCo2Performance, gasCo2Performance) => {
  const co2Performance = {};
  const keys = new Set([...Object.keys(electricCo2Performance), ...Object.keys(gasCo2Performance)]);
  keys.forEach((key) => {
    if (electricCo2Performance[key] && gasCo2Performance[key]) {
      // 該当年の電気ガス両方にCO2排出量実績値が存在する場合
      co2Performance[key] = electricCo2Performance[key].map((value, index) => {
        if (value && gasCo2Performance[key][index]) {
          // 該当月の電気ガス両方にCO2排出量実績値が存在する場合、合計する
          return value + gasCo2Performance[key][index];
        }
        // 該当月の電気ガス片方にCO2排出量実績値が存在する場合、存在する方のデータを採用する
        return value ?? gasCo2Performance[key][index];
      });
    } else {
      // 該当年の電気ガス片方にCO2排出量実績値が存在する場合、存在する方のデータを採用する
      co2Performance[key] = electricCo2Performance[key] ?? gasCo2Performance[key];
    }
  });
  return co2Performance;
}

const convertElectricalUsageList = (electricalUsageList) => {
  const electricPerformance = {};
  const electricTarget = {};
  const electricCo2Performance = {};
  const electricYears = new Set();
  // APIから取得してきた電気使用量リスト「electricalUsageList」をループする
  electricalUsageList.forEach((item) => {
    let { year, month, performance, target, co2Performance } = item;
    const indexOfMonth = getIndexOfMonth(month);
    const fiscalYear = toFiscalYear(year, month);
    electricYears.add(fiscalYear);

    // 電気使用実績値データ「electricPerformance」の作成
    assignData(electricPerformance, fiscalYear, indexOfMonth, performance);
    // 電気使用基準値データ「electricTarget」の作成
    assignData(electricTarget, fiscalYear, indexOfMonth, target);
    // 電気CO2実績値データ「electricCo2Performance」の作成
    assignData(electricCo2Performance, fiscalYear, indexOfMonth, co2Performance);
  });
  return {
    electricPerformance,
    electricTarget,
    electricCo2Performance,
    electricYears
  };
}

const convertGasUsageList = (gasUsageList) => {
  const gasPerformance = {};
  const gasTarget = {};
  const gasCo2Performance = {};
  const gasYears = new Set();
  // APIから取得してきたガス使用量リスト「gasUsageList」をループする
  gasUsageList.forEach((item) => {
    let { year, month, performance, target, co2Performance } = item;
    const indexOfMonth = getIndexOfMonth(month);
    const fiscalYear = toFiscalYear(year, month);
    gasYears.add(fiscalYear);
    // ガス使用実績値データ「gasPerformance」の作成
    assignData(gasPerformance, fiscalYear, indexOfMonth, performance);
    // ガス使用基準値データ「gasTarget」の作成
    assignData(gasTarget, fiscalYear, indexOfMonth, target);
    // ガスCO2実績値データ「gasCo2Performance」の作成
    assignData(gasCo2Performance, fiscalYear, indexOfMonth, co2Performance);
  });
  return {
    gasPerformance,
    gasTarget,
    gasCo2Performance,
    gasYears
  }
}

const convertEarnPointList = (earnPointList) => {
  const pointList = {};
  const evaluationStatusList = {};
  const evaluationStatusIconList = {};
  const pointListYears = new Set();
  // APIから取得してきた獲得ポイント数リスト「earnPointList」をループする
  earnPointList.forEach((item) => {
    let { year, month, point, evaluationStatus } = item;
    const indexOfMonth = getIndexOfMonth(month);
    const fiscalYear = toFiscalYear(year, month);
    pointListYears.add(fiscalYear);

    // 獲得ポイントデータ「pointList」の作成
    assignData(pointList, fiscalYear, indexOfMonth, point);
    // 削減CO2評価ステータスリスト「evaluationStatusList」の作成
    assignData(evaluationStatusList, fiscalYear, indexOfMonth, evaluationStatus);
    // 削減CO2評価ステータスアイコンリスト「evaluationStatusIconList」の作成
    assignData(evaluationStatusIconList, fiscalYear, indexOfMonth, evaluationIcons?.[evaluationStatus] ?? null);
  });
  return {
    pointList,
    evaluationStatusList,
    evaluationStatusIconList,
    pointListYears
  }
}

const convertCo2UsageList = (co2UsageList) => {
  const co2Target = {};
  const co2Years = new Set();
  // APIから取得してきたCO2排出量リスト「co2UsageList」をループする
  co2UsageList.forEach((item) => {
    let { year, month, target } = item;
    const indexOfMonth = getIndexOfMonth(month);
    const fiscalYear = toFiscalYear(year, month);
    co2Years.add(fiscalYear);

    // 電気ガス合計CO2基準値データ「co2Target」の作成
    assignData(co2Target, fiscalYear, indexOfMonth, target);
  });
  return { co2Target, co2Years };
}

const getInitialYear = (co2Performance) => {
  const co2PerformanceYears = Object.keys(co2Performance).map(Number);
  const initialYear = Math.max(...co2PerformanceYears);
  return initialYear;
}

const getNearestActivity = (energyData) => {
  const year = Math.max(...Object.keys(energyData.evaluationStatusList).map(Number));
  const index = energyData.evaluationStatusList[year]
    ? energyData.evaluationStatusList[year].findLastIndex(value => value !== null)
    : -1;
  const month = index === -1
    ? null : index < 9
      ? index + 4 : index - 8;
  const co2Performance = energyData.co2Performance?.[year]?.[index] ?? 0;
  const co2Target = energyData.co2Target?.[year]?.[index] ?? 0;
  const co2Reduction = co2Performance - co2Target;
  const point = energyData.pointList?.[year]?.[index] ?? 0;
  const evaluationStatusIcon = energyData.evaluationStatusIconList?.[year]?.[index] ?? null;

  return {
    year,
    month,
    co2Performance,
    co2Target,
    co2Reduction,
    point,
    evaluationStatusIcon
  };
}
