// 必須項目
const requiredFields = [
  "representativeLastName",
  "representativeFirstName",
  "representativeBirthYear",
  "representativeBirthMonth",
  "representativeBirthDay",
  "usageClassificationId",
  "isDeliveryInfoEqualToEntryInfo",
  "deliveryTell",
  "energyVenderId",
  "usageFromYear",
  "usageFromMonth",
  "usageFromDay",
];
// 「送付先郵便番号」「送付先住所」「送付先宛名」
// isDeliveryInfoEqualToEntryInfoがfalseの場合に必須チェックを行う
const requiredFieldsDelivery = [
  "deliveryZip",
  "deliveryAddress",
  "deliveryName"
];


// 必須チェック処理
export const checkRequired = (formData) => {
  // 必須項目の必須チェックを実施する
  for (let name of requiredFields) {
    if (formData[name] === "" || formData[name] === null) {
      // 必須項目のなかで未入力の項目があった場合、falseを返す
      return false;
    }
  }

  // 「お届け先」項目で「下記の住所へ送る」が選択されている場合、「送付先郵便番号」「送付先住所」「送付先宛名」についても必須チェックを実施する
  if (formData["isDeliveryInfoEqualToEntryInfo"] === false || formData["isDeliveryInfoEqualToEntryInfo"] === "false") {
    for (let name of requiredFieldsDelivery) {
      if (formData[name] === "" || formData[name] === null) {
        // 必須項目のなかで未入力の項目があった場合、falseを返す
        return false;
      }
    }
  }

  // 必須項目が全て入力されている場合、trueを返す
  return true;
}
