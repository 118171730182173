import { useNavigate } from "react-router-dom";
import { usePostRequest } from "../../../hooks/usePostRequest";
import style from "../style/confirmPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ConfirmPopup = ({
  isOpen,
  formData,
  closeConfirmPopup,
  openResultPopup,
}) => {
  const navigate = useNavigate();
  const API_URL = "/api/present";
  const { post, isLoading } = usePostRequest({
    url: API_URL,
    postData: formData,
    onSuccess() {
      closeConfirmPopup();
      openResultPopup();
    },
    onValidError(data) {
      // TODO: 存在しないケース、とりあえずエラー画面へ
      navigate("/error");
    }
  });

  const onClick = () => {
    // post処理中にクリックされた場合にはイベントをキャンセルする
    if (isLoading) return;
    post();
  }

  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <img
          src={closeModal}
          alt="close modal"
          className={style["close"]}
          onClick={() => {
            closeConfirmPopup()
          }}
        />
        <p className={style["text"]}>
          交換後は<br />
          キャンセルできません。<br />
          <br />
          よろしければ<br />
          OKボタンを押してください。
        </p>
        <a className={style["button"]} onClick={onClick}>OK</a>
      </div>
    </div >
  );
}
