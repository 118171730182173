import { Link } from "react-router-dom";
import style from "../style/tags.module.css";

export const Tags = ({
  tagList,
  selectedTagId
}) => {

  return (
    <div className={style["tags-wrap"]}>
      <DefaultTag selectedTagId={selectedTagId} />
      {
        tagList.map((tag) => (
          String(tag.id) === selectedTagId
            ? <SelectedTag tag={tag} key={tag.id} />
            : <Tag tag={tag} key={tag.id} />
        ))
      }
    </div>
  );
}

const DefaultTag = ({ selectedTagId }) => {
  const defaultTag = { id: "", name: "すべて" };

  if (selectedTagId === null || selectedTagId === "") {
    return <SelectedTag tag={defaultTag} />;
  }

  return <Tag tag={defaultTag} />;
}

const SelectedTag = ({ tag }) => {
  return (
    <>
      <span className={`${style["tag"]} ${style["selected"]}`}>{tag.name}</span>
    </>
  );
}

const Tag = ({ tag }) => {
  return (
    <>
      <Link to={`/list?tagId=${tag.id}`} className={style["tag"]}>{tag.name}</Link>
    </>
  );
}