import { Link } from "react-router-dom";
import { Section } from "./Section";
import {
  InputTitle,
  InputText,
  InputNumber,
  InputReadOnly,
  Select
} from "./FormParts";
import style from "../style/style.module.css";

export const EnergySection = ({ formData, setValue, energyVenderIdOptions, validError, isEntered }) => {
  return (
    <Section title="エネルギー契約">
      <div className={style["energy-vender-id-wrap"]}>
        <InputTitle
          title="予定契約先"
          required
        />
        <p className={style["text-blue"]}>選択肢にないエネルギー供給会社と契約された場合は、サス活へ参加いただけません。</p>
        <Select
          name="energyVenderId"
          options={energyVenderIdOptions}
          placeholder="--　選択してください　--"
          defaultValue={formData.energyVenderId ?? "3"}
          setValue={setValue}
          fullWidth
          validError={validError}
        />
      </div>
      <InputTitle
        title="入居予定日"
        required
      />
      <div className={style["input-movein-wrap"]}>
        <InputNumber
          placeholder="2024"
          value={formData.usageFromYear ?? ""}
          name="usageFromYear"
          maxlength={4}
          setValue={setValue}
          validError={validError}
        />
        <span className={style["text-blue"]}>年</span>
        <InputNumber
          placeholder="12"
          value={formData.usageFromMonth ?? ""}
          name="usageFromMonth"
          maxlength={2}
          setValue={setValue}
          validError={validError}
        />
        <span className={style["text-blue"]}>月</span>
        <InputNumber
          placeholder="30"
          value={formData.usageFromDay ?? ""}
          name="usageFromDay"
          maxlength={2}
          setValue={setValue}
          validError={validError}
        />
        <span className={style["text-blue"]}>日</span>
      </div>
      <InputTitle
        title="省エネポイント反映"
      />
      <p className={`${style["text-blue"]} ${style["mb-11"]}`}>エネルギー供給会社の初回検針の後、開始します。</p>
      <InquiryInfo isEntered={isEntered} />
    </Section>
  );
}

const InquiryInfo = ({ isEntered }) => {
  if (isEntered === 0) {
    return (
      <p>※エネルギー供給会社の初回検針後、3か月経過しても省エネポイントが反映されない場合は、事務局へお問い合わせください。</p>
    );
  }
  return (
    <p>※エネルギー供給会社の初回検針後、3か月経過しても省エネポイントが反映されない場合は、<Link to="/secretariatContact">事務局へお問い合わせ</Link>ください。</p>
  );
}
