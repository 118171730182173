import { Link } from "react-router-dom";
import { Section } from "./Section";
import style from "../style/style.module.css";

export const EnergySectionAfterConnected = () => {
  return (
    <Section title="エネルギー契約">
      <p className={style["mb-23"]}>※エントリー後に契約先を変更する場合は、<Link to={"/secretariatContact"}>事務局へご一報ください</Link>。エントリー引き継ぎの確認をいたします。</p>
      <p>※ご退去の場合は、退去の手続きをお願いいたします。</p>
    </Section>
  );
}