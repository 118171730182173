// 賞品リストをタグIDでフィルタする
export const filterPrizeListWithTagId = (prizeList, tagId) => {
  // 「すべて」タグが選択されている場合、全ての賞品を返す
  if (tagId === null || tagId === "") {
    return prizeList;
  }

  const filteredPrizeList = prizeList.filter((prize) => (
    prize.tagIdList.some((prizeTag) => (
      String(prizeTag.id) === tagId
    ))
  ));

  return filteredPrizeList;
}