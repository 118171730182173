import style from "../style/list.module.css";

export const List = ({
  list,
}) => {
  return (
    <div className={style["list-area"]}>
      {
        list.length === 0 && <p className={style["no-contact"]}>履歴はありません。</p>
      }
      {
        list.map((item, index) => (
          <div key={index} className={`${style["list"]}`}>
            <p className={style["list-date"]}>{item.date}</p>
            <p className={style["list-class"]}>{item.class === "ポイント付与届出" ? "コードでポイント獲得" : "事務局お問い合わせ"}</p>
            <p className={style["list-content"]}>{item.content}</p>
            <p className={style["list-notes"]}>{item.class === "ポイント付与届出" ? "(獲得ポイントの反映は翌月末の予定です。)" : "(返信まで3営業日程度かかる場合があります。)"}</p>
          </div>
        ))
      }
    </div>
  );
}
