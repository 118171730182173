import { Section } from "./Section";
import {
  InputTitle,
  InputText,
  InputNumber,
  InputReadOnly,
  InputRadio,
  Select
} from "./FormParts";
import style from "../style/style.module.css";

export const BasicInfoSection = ({
  postalCode,
  state,
  city,
  addressLine1,
  addressLine2,
  mansionNameJapanese,
  buildingNameJapanese,
  roomName,
  formData,
  setValue,
  usageClassificationIdOptions,
  validError
}) => {

  return (
    <Section title="基本情報">
      <InputTitle title="エントリー物件" />
      <InputReadOnly
        defaultValue={`${mansionNameJapanese}${buildingNameJapanese}\n${roomName}`}
        fullWidth
      />
      <InputTitle
        title="エントリー氏名　代表者名"
        required
      />
      <div className={style["input-lastname-wrap"]}>
        <span className={style["text-blue"]}>姓</span>
        <InputText
          setValue={setValue}
          value={formData.representativeLastName ?? ""}
          name="representativeLastName"
          maxlength={64}
          validError={validError}
        />
      </div>
      <div className={style["input-firstname-wrap"]}>
        <span className={style["text-blue"]}>名</span>
        <InputText
          setValue={setValue}
          value={formData.representativeFirstName ?? ""}
          name="representativeFirstName"
          maxlength={64}
          validError={validError}
        />
      </div>
      <InputTitle
        title="生年月日"
        required
      />
      <p className={style["text-blue"]}>本人確認に使用させていただくことがあります。</p>
      <div className={style["input-birth-wrap"]}>
        <InputNumber
          placeholder="1990"
          setValue={setValue}
          value={formData.representativeBirthYear ?? ""}
          name="representativeBirthYear"
          maxlength={4}
          validError={validError}
        />
        <span className={style["text-blue"]}>年</span>
        <InputNumber
          placeholder="12"
          setValue={setValue}
          value={formData.representativeBirthMonth ?? ""}
          name="representativeBirthMonth"
          maxlength={2}
          validError={validError}
        />
        <span className={style["text-blue"]}>月</span>
        <InputNumber
          placeholder="30"
          setValue={setValue}
          value={formData.representativeBirthDay ?? ""}
          name="representativeBirthDay"
          maxlength={2}
          validError={validError}
        />
        <span className={style["text-blue"]}>日</span>
      </div>
      <InputTitle
        title="主に居住する人数"
      />
      <div className={style["input-residents-wrap"]}>
        <p className={style["label"]}>世帯人数</p>
        <Select
          options={[
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9~", label: "9~" }
          ]}
          placeholder="0"
          defaultValue={formData.residentsCount ?? ""}
          setValue={setValue}
          name="residentsCount"
          validError={validError}
        />
        <p className={style["unit"]}>人</p>
      </div>
      <div className={style["input-child-residents-wrap"]}>
        <p className={style["label"]}>うち18歳未満</p>
        <Select
          options={[
            { value: "0", label: "0" },
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9~", label: "9~" }
          ]}
          placeholder="0"
          defaultValue={formData.childResidentsCount ?? ""}
          setValue={setValue}
          name="childResidentsCount"
          validError={validError}
        />
        <p className={style["unit"]}>人</p>
      </div>
      <InputTitle
        title="入居予定者"
        required
      />
      <div className={style["radio-usage-classification-wrap"]}>
        <InputRadio
          options={usageClassificationIdOptions}
          defaultValue={String(formData.usageClassificationId ?? 1)}
          name="usageClassificationId"
          setValue={setValue}
        />
      </div>
    </Section>
  );
}