

export const checkClient = (formData) => {
  const fieldErrorList = [];

  // ポイント付与コードの文字数制限
  if (formData.pointAssignmentCode.length > 16) {
    fieldErrorList.push({
      field: "pointAssignmentCode",
      fieldErrorMessage: "ポイント付与コード：文字数オーバーです。"
    });
  }

  // 入力値に問題がある場合、objectを返す
  if (fieldErrorList.length > 0) {
    return fieldErrorList;
  }

  // 入力値に問題がない場合、trueを返す
  return true;
}
