import { useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { List } from "./component/List";
import { useGetRequest } from "../../hooks/useGetRequest";
import style from "./style/main.module.css";

/**
 * ポイント有効期限画面
 */
export const Page = () => {
  // 内部APIの実行
  // クエリ文字列からroomId,developerIdを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  const API_URL = "/api/point/limit";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <Header />
      <main className={style["main"]}>
        <p className={style["total-valid-point-wrap"]}>
          保有ポイント
          <span className={style["total-valid-point"]}>
            {data.totalValidPoint && Number(data.totalValidPoint).toLocaleString("ja-JP")}
            <span className={style["total-valid-point-unit"]}>pt</span>
          </span>
        </p>
        {
          data.pointsByExpirationDateList && data.pointsByExpirationDateList.length > 0 &&
          <p className={style["points-by-expiration-date"]}>
            {data.pointsByExpirationDateList[0].expirationDate}末に
            {Number(data.pointsByExpirationDateList[0].point).toLocaleString("ja-JP")}pt期限切れ
          </p>
        }
        <span className={style["line-graduation"]}></span>
        <List
          list={data.pointsByExpirationDateList || []}
        />
      </main>
    </>
  );
}
