import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { Content } from "./component/Content";
import { useGetRequest } from "../../hooks/useGetRequest";

/**
 * ホーム画面
 */
export const Page = () => {

  // 受信データ
  const [formData, setFormData] = useState({});

  // 内部APIの実行
  // クエリ文字列からroomId,developerIdを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  const API_URL = "/api/home";

  const { data, error, isLoading } = useGetRequest(API_URL, params);

  useEffect(() => {
    setFormData(prevState => {
      const newState = {
        ...prevState,
        ...data
      }
      return newState;
    });
  }, [data]);

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <Header
        formData={formData}
      />
      <Content
        formData={formData}
      />
    </>
  );
}