

export const checkClient = (formData) => {
  const fieldErrorList = [];

  // 問い合わせ内容の文字数制限
  if (formData.content.length > 1000) {
    fieldErrorList.push({
      field: "content",
      fieldErrorMessage: "問い合わせ内容：文字数オーバーです。"
    });
  }

  // 入力値に問題がある場合、objectを返す
  if (fieldErrorList.length > 0) {
    return fieldErrorList;
  }

  // 入力値に問題がない場合、trueを返す
  return true;
}
