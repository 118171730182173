import { Link } from "react-router-dom";
import { filterPrizeListWithTagList } from "../utility/filterPrizeListWithTagList";
import pWhite from "../image/icon_p_white.svg";
import pBlue from "../image/icon_p_blue.svg";
import noImage from "../image/logo_gray_500×500.png";
// import noImage from "../image/logo_500×500.png";
import style from "../style/content.module.css";

const displayPrizeTagIdStep1 = 21;
const displayPrizeTagIdStep2 = 22;
const displayPrizeTagIdStep3 = 23;
const displayPrizeTagIdStep4 = 25;

const displayPrizeTagNameStep1 = "おすすめ";
const displayPrizeTagNameStep2 = "先着順でもらえる";
const displayPrizeTagNameStep3 = "抽選であたる";
const displayPrizeTagNameStep4 = "新着特典";

const displayPrizeTagCountStep1 = 4;
const displayPrizeTagCountStep2 = 2;
const displayPrizeTagCountStep3 = 4;
const displayPrizeTagCountStep4 = 4;

/**
 * ホーム画面コンテント
 */

export const Content = ({ formData }) => {

    const prizeLength = filterPrizeListWithTagList(formData.prizeList || [], [{ id: displayPrizeTagIdStep1 }, { id: displayPrizeTagIdStep2 }, { id: displayPrizeTagIdStep3 }, { id: displayPrizeTagIdStep4 }]).length;

    return (
        <>
            <div className={style["content"]}>
                <Info />
                {
                    prizeLength === 0 && <NoPrize />
                }
                <Recommend formData={formData} />
                <AllApplicants formData={formData} />
                <TokyoUnite formData={formData} />
                <Eleminist formData={formData} />
            </div>
            {formData.isEntered !== 0 && <Footer />}
        </>
    );
}

const Info = () => {
    return (
        <div className={style["sus-info-area"]}>
            <p className={style["sus-info-text"]}>ポイントを交換する</p>
            <div className={style["sus-info-bar"]}></div>
        </div>
    );
}

const NoPrize = () => {
    return (
        <p className={style["no-prize"]}>ただいま準備中です</p>
    );
}

const Recommend = ({ formData }) => {
    const list = [];
    const prizeList = filterPrizeListWithTagList(formData.prizeList || [], [{ id: displayPrizeTagIdStep1 }]).slice(0, displayPrizeTagCountStep1);

    if (prizeList.length === 0) {
        return null;
    }

    for (const [i, prize] of prizeList.entries()) {
        const link = "/detail?id=" + prize.id;
        let thumbnailHtml = "";
        if (0 < prize.images.length) {
            thumbnailHtml = <img className={style["slide-box-image"]} src={prize.images[0]} />;
        } else if (prize.images.length === 0) {
            thumbnailHtml = <img className={style["slide-box-image"]} src={noImage} />;
        }
        let newTagHtml = "";
        if (prize.newTag) {
            newTagHtml = <p className={style["slide-box-new-tag"]}>NEW</p>;
        }
        let classficationTagHtml = <span className={style["slide-box-bottom-image-area-tag"]}><ClassficationTag classfication={prize.classfication} /></span>;

        list.push(
            <div className={style["slide-box"]} key={i}>
                <a className={style["slide-box-link"]} href={link}>
                    {thumbnailHtml}
                    {newTagHtml}
                    <div className={style["slide-box-bottom-area"]}>
                        <p className={style["slide-box-bottom-area-title"]}>{prize.title}</p>
                        <div className={style["slide-box-bottom-image-area"]}>
                            <img className={style["slide-box-bottom-image-area-p-white"]} src={pWhite} />
                            <span className={style["slide-box-bottom-image-area-point"]}>
                                <span className={style["point"]}>{prize.applicationPoint && prize.applicationPoint.toLocaleString("ja-JP")}</span>
                                <span className={style["unit"]}>pt</span>
                            </span>
                            {classficationTagHtml}
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    return (
        <div>
            <p className={style["slide-wrap-info"]}>{displayPrizeTagNameStep1}</p>
            <div className={style["slide-wrap"]}>
                {list}
            </div>
            <Link to={"/list?tagId=" + displayPrizeTagIdStep1} className={style["link-entry"]}>もっと見る</Link>
        </div>
    );
}

const AllApplicants = ({ formData }) => {
    const list = [];
    const prizeList = filterPrizeListWithTagList(formData.prizeList || [], [{ id: displayPrizeTagIdStep2 }]).slice(0, displayPrizeTagCountStep2);

    if (prizeList.length === 0) {
        return null;
    }

    for (const [i, prize] of prizeList.entries()) {
        const link = "/detail?id=" + prize.id;
        let thumbnailHtml = "";
        if (0 < prize.images.length) {
            thumbnailHtml = <img className={style["image"]} src={prize.images[0]} />;
        } else if (prize.images.length === 0) {
            thumbnailHtml = <img className={style["image"]} src={noImage} />;
        }
        let newTagHtml = "";
        if (prize.newTag) {
            newTagHtml = <p className={style["slide-box-new-tag"]}>NEW</p>;
        }
        let classficationTagHtml = <ClassficationTag classfication={prize.classfication} />;

        list.push(
            <a href={link} className={style["ten-point-sus"]} key={i}>
                <div className={style["image-wrap"]}>{thumbnailHtml}</div>
                {newTagHtml}
                <section className={style["desc"]}>
                    <p className={style["title"]}>{prize.title}</p>
                    <p className={style["detail"]}>{prize.detail}</p>
                    <section className={style["attach"]}>
                        <section className={style["point-wrap"]}>
                            <img src={pBlue} />
                            <span className={style["point"]}>
                                {prize.applicationPoint && prize.applicationPoint.toLocaleString("ja-JP")}
                                <span className={style["unit"]}>pt</span>
                            </span>
                        </section>
                        {classficationTagHtml}
                    </section>
                </section>
            </a>
        );
    }

    return (
        <div>
            <p className={style["slide-wrap-info"]}>{displayPrizeTagNameStep2}</p>
            <div className={style["ten-point-sus-wrap"]}>
                {list}
            </div>
            <Link to={"/list?tagId=" + displayPrizeTagIdStep2} className={style["link-entry"]}>もっと見る</Link>
        </div>
    );
}

const TokyoUnite = ({ formData }) => {
    const list = [];
    const prizeList = filterPrizeListWithTagList(formData.prizeList || [], [{ id: displayPrizeTagIdStep3 }]).slice(0, displayPrizeTagCountStep3);

    if (prizeList.length === 0) {
        return null;
    }

    for (const [i, prize] of prizeList.entries()) {
        const link = "/detail?id=" + prize.id;
        let thumbnailHtml = "";
        if (0 < prize.images.length) {
            thumbnailHtml = <img className={style["slide-box-image"]} src={prize.images[0]} />;
        } else if (prize.images.length === 0) {
            thumbnailHtml = <img className={style["slide-box-image"]} src={noImage} />;
        }
        let newTagHtml = "";
        if (prize.newTag) {
            newTagHtml = <p className={style["slide-box-new-tag"]}>NEW</p>;
        }
        let classficationTagHtml = <span className={style["slide-box-bottom-image-area-tag"]}><ClassficationTag classfication={prize.classfication} /></span>;

        list.push(
            <div className={style["box"]} key={i}>
                <div className={style["box-inner"]}>
                    <a className={style["slide-box-link"]} href={link}>
                        {thumbnailHtml}
                        {newTagHtml}
                        <div className={style["slide-box-bottom-area"]}>
                            <p className={style["slide-box-bottom-area-title"]}>{prize.title}</p>
                            <div className={style["slide-box-bottom-image-area"]}>
                                <img className={style["slide-box-bottom-image-area-p-white"]} src={pWhite} />
                                <span className={style["slide-box-bottom-image-area-point"]}>
                                    <span className={style["point"]}>{prize.applicationPoint && prize.applicationPoint.toLocaleString("ja-JP")}</span>
                                    <span className={style["unit"]}>pt</span>
                                </span>
                                {classficationTagHtml}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        );
    }


    return (
        <div>
            <p className={style["slide-wrap-info"]}>{displayPrizeTagNameStep3}</p>
            <div className={style["deadline-list"]}>
                {list}
            </div>
            <Link to={"/list?tagId=" + displayPrizeTagIdStep3} className={style["link-entry"]}>もっと見る</Link>
        </div>
    );

}


const Eleminist = ({ formData }) => {
    const prizeList = filterPrizeListWithTagList(formData.prizeList || [], [{ id: displayPrizeTagIdStep4 }]).slice(0, displayPrizeTagCountStep4);

    if (prizeList.length === 0) {
        return null;
    }

    const list = prizeList.map((prize, index) => (
        <a href={`/detail?id=${prize.id}`} className={style["shopping-slide-box"]} key={index}>
            {prize.newTag && <p className={style["shopping-slide-new-tag"]}>NEW</p>}
            <div className={style["shopping-slide-image-wrap"]}>
                {
                    prize.images.length > 0
                        ? <img src={prize.images[0]} className={style["shopping-slide-image"]} />
                        : <img src={noImage} className={style["shopping-slide-image"]} />
                }
            </div>
            <div className={style["shopping-slide-desc"]}>
                <p className={style["shopping-slide-title"]}>{prize.title}</p>
                <p className={style["shopping-slide-detail"]}>{prize.detail}</p>
                <div className={style["shopping-slide-bottom"]}>
                    <img src={pBlue} />
                    <span className={style["shopping-slide-point"]}>
                        {prize.applicationPoint && prize.applicationPoint.toLocaleString("ja-JP")}
                        <span className={style["shopping-slide-point-unit"]}>pt</span>
                    </span>
                    <span className={style["slide-box-bottom-image-area-tag"]}><ClassficationTag classfication={prize.classfication} /></span>
                </div>
            </div>
        </a>
    ));

    return (
        <div>
            <p className={style["slide-wrap-info"]}>{displayPrizeTagNameStep4}</p>
            <div className={style["shopping-slide-wrap"]}>
                {list}
            </div>
            <Link to={"/list?tagId=" + displayPrizeTagIdStep4} className={style["link-entry"]}>もっと見る</Link>
        </div>
    );
}

const Footer = () => {
    return (
        <footer className={style["footer"]}>
            <div className={style["grid"]}>
                <a href="/list" className={style["link-sus"]}>特典一覧</a>
                <a href="/pointEarning" className={style["link-sus"]}>コードで<br />ポイント獲得</a>
                <a href="/entry" className={style["link-sus"]}>エントリー情報<br />確認・変更</a>
                <a href="/secretariatContact" className={style["link-sus"]}>事務局<br />お問い合わせ</a>
            </div>
        </footer>
    );
}

const ClassficationTag = ({ classfication }) => {
    if (classfication === 1) {
        return <span className={style["immediate-tag"]}>もれなく</span>;
    }

    if (classfication === 2) {
        return <span className={style["lottery-tag"]}>抽選</span>;
    }

    if (classfication === 3) {
        return <span className={style["donation-tag"]}>寄付</span>;
    }

    if (classfication === 4) {
        return <span className={style["fcfs-tag"]}>先着</span>;
    }

    return null;
}
