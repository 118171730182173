import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './mfr/pages/App';
import "./mfr/assets/style/common.css";

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
