import style from "../style/activity.module.css";

export const ActivityBeforeEntryComplete = () => {
  return (
    <div className={style["activity-before-entry-complete"]}>
      <p className={style["point-reflection-title"]}>省エネポイントの反映</p>
      <p className={style["point-reflection"]}>エネルギー供給会社の初回検針の後、開始します。</p>
      <div></div>
      <a href="https://service.appbox.jp/kurasus/sus-katsu-list/" className={style["link-sus"]}>
        <span className={style["main"]}>
          「く<span className={style["red"]}>ら</span>し<span className={style["red"]}>の</span>サ<span className={style["red"]}>ス</span>活」とは？
        </span><br />
        サス活をやってみよう！ポイントルールはこちら
      </a>
    </div>
  );
}