import { useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { List } from "./component/List";
import { useGetRequest } from "../../hooks/useGetRequest";
import style from "./style/main.module.css";

/**
 * 事務局連絡履歴画面
 */
export const Page = () => {
  // 内部APIの実行
  // クエリ文字列からroomId,developerIdを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  const API_URL = "/api/secretariat/history";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <Header />
      <main className={style["main"]}>
        <List
          list={data.contactList ?? []}
        />
      </main>
    </>
  );
}
