import { useEffect } from "react";
import { useCounter } from "../hooks/useCounter";
import style from "../style/buttonArea.module.css";

export const ButtonArea = ({
  totalValidPoint,
  applicationPoint,
  applicableUnit,
  appliedUnit,
  applicationDeadline,
  openConfirmPopup,
  setFormData
}) => {
  const { count, decrement, increment } = useCounter(0);
  const isMin = count <= 0;
  const isMax = count >= applicableUnit || count >= Math.floor(totalValidPoint / applicationPoint);
  const isNotApplicable = applicationDeadline || totalValidPoint < applicationPoint || applicableUnit <= 0;
  const usePoint = count * applicationPoint;
  const leftPoint = totalValidPoint - count * applicationPoint;

  useEffect(() => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        unit: count ?? 0
      };
    });
  }, [count]);

  return (
    <div className={style["button-area"]}>
      <p className={style["text-info"]}>
        口数を選択のうえ<br />
        交換ボタンをおしてください。
      </p>
      <div className={style["point-area"]}>
        <div className={style["total-valid-point"]}>
          <span className={style["title"]}>保有ポイント</span>
          <span>
            <span className={style["point"]}>{totalValidPoint.toLocaleString("ja-JP")}</span>
            <span className={style["unit"]}>pt</span>
          </span>
        </div>
        <div className={style["use-point"]}>
          <span className={style["title"]}>交換ポイント</span>
          <span>
            <span className={style["point"]}>{usePoint.toLocaleString("ja-JP")}</span>
            <span className={style["unit"]}>pt</span>
          </span>
        </div>
        <div className={style["left-point"]}>
          <span className={style["title"]}>
            交換後の<br />
            保有ポイント
          </span>
          <span>
            <span className={style["point"]}>{leftPoint.toLocaleString("ja-JP")}</span>
            <span className={style["unit"]}>pt</span>
          </span>
        </div>
      </div>
      <div className={style["amount-select"]}>
        <DecrementButton
          decrement={decrement}
          isMin={isMin}
          applicationDeadline={applicationDeadline}
        />
        <span className={`${style["amount"]} ${isNotApplicable ? style["gray"] : ""}`}>{count.toLocaleString("ja-JP")}</span>
        <IncrementButton
          increment={increment}
          isMax={isMax}
          applicationDeadline={applicationDeadline}
        />
      </div>
      <ApplicationButton
        count={count}
        totalValidPoint={totalValidPoint}
        applicationDeadline={applicationDeadline}
        applicationPoint={applicationPoint}
        appliedUnit={appliedUnit}
        isNotApplicable={isNotApplicable}
        openConfirmPopup={openConfirmPopup}
      />
    </div>
  );
}

const DecrementButton = ({ decrement, isMin, applicationDeadline }) => {
  if (isMin || applicationDeadline) {
    return <span className={style["decrement-disabled"]}>ー</span>;
  }

  return (
    <>
      <a
        className={style["decrement"]}
        onClick={() => { decrement() }}
      >ー</a>
    </>
  );
}

const IncrementButton = ({ increment, isMax, applicationDeadline }) => {
  if (isMax || applicationDeadline) {
    return <span className={style["decrement-disabled"]}>＋</span>;
  }

  return (
    <>
      <a
        className={style["increment"]}
        onClick={() => { increment() }}
      >＋</a>
    </>
  );
}

const ApplicationButton = ({ count, isNotApplicable, openConfirmPopup }) => {
  if (isNotApplicable) {
    return <span className={style["application-button-disabled"]}>交換できません</span>;
  }

  if (count <= 0) {
    return <span className={style["application-button-disabled"]}>交換</span>;
  }

  return (
    <a
      className={style["application-button"]}
      onClick={openConfirmPopup}
    >
      交換
    </a>
  );
}
