import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const useGetRequest = (url, params = {}) => {
  const navigate = useNavigate();
  const [response, setResponse] = useState({
    data: null,
    error: null,
    isLoading: true
  });

  useEffect(() => {
    getRequest(url, params);
  }, []);

  const getRequest = (url, params) => {
    axios.get(url, { params })
      .then(response => { // 2XX
        setResponse({
          data: response.data,
          error: null,
          isLoading: false
        });
      })
      .catch(error => {
        if (error.response) { // 2XX以外
          setResponse({
            data: null,
            error: error.response.data,
            isLoading: false
          });
          navigate("/error", { state: error.response.data });
        } else { // レスポンスなし
          const unexpectedError = {
            errorMessage: "インターネットに接続できません\n通信環境の問題やシステムメンテナンスの可能性があります",
            errorCode: "EFNT002"
          }
          setResponse({
            data: null,
            error: unexpectedError,
            isLoading: false
          });
          navigate("/error", { state: unexpectedError });
        }
      });
  }

  return response;
}