import { Link } from "react-router-dom";
import style from "../style/style.module.css";
import arrowIcon from "../image/arrow_right.svg";

export const ButtonArea = ({ isRequiredValid, openConfirmPopup }) => {
  return (
    <div className={style["buttonarea"]}>
      {isRequiredValid
        ? <a className={style["button"]} onClick={() => {openConfirmPopup();}}>送信</a>
        : <a className={style["button-not-click"]}>送信</a>
      }
      <div className={style["history-buttonarea"]}>
        <Link
          to="/secretariatHistory" className={style["history-button"]}
        >連絡履歴を見る<img src={arrowIcon} alt="arrow" className={style["arrow-right"]} /></Link>
      </div>
    </div>
  );
}
