import { useNavigate } from "react-router-dom";
import style from "../style/header.module.css";
import arrowIcon from "../image/header_arrow_left.svg";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <header>
      <h1 className={style["page-heading"]}>
        <a
          className={style["arrow-icon"]}
          onClick={() => {
            // 賞品一覧画面で賞品タグを選択した履歴に飛ばないようホーム画面を指定する
            navigate("/");
          }}
        >
          <img
            src={arrowIcon}
            alt="arrow"
          />
        </a>
        特典一覧
      </h1>
    </header>
  );
}