import style from "../style/prizeDescription.module.css";
import pBlue from "../image/icon_p_blue.svg";

export const PrizeDescription = ({
  classfication,
  newTag,
  applicationDeadline,
  title,
  detail,
  applicationPoint,
  applicationPeriod,
  applicationTerms,
  minnerDitail,
  note
}) => {
  return (
    <>
      <div className={style["back-white"]}>
        <div className={style["top-area"]}>
          <div className={style["point-area"]}>
            <img className={style["icon"]} src={pBlue} alt="p" />
            <span className={style["point"]}>{applicationPoint.toLocaleString("ja-JP")}</span>
            <span className={style["unit"]}>pt</span>
          </div>
          <div className={style["tag-area"]}>
            <StatusTag
              newTag={newTag}
              applicationDeadline={applicationDeadline}
            />
            <ClassficationTag
              classfication={classfication}
            />
          </div>
        </div>
        <p className={style["title"]}>{title}</p>
        <p className={style["detail"]}>{detail}</p>
      </div>
      <div className={style["back-gray"]}>
        <div className={style["item"]}>
          <p className={style["head"]}>交換期間</p>
          <p className={style["applicationPeriod"]}>{applicationPeriod}</p>
        </div>
        {
          applicationTerms !== null &&
          <div className={style["item"]}>
            <p className={style["head"]}>交換に必要なポイント</p>
            <p className={style["applicationTerms"]}>{applicationTerms}</p>
          </div>
        }
        {
          minnerDitail !== null &&
          <div className={style["item"]}>
            <p className={style["head"]}>抽選結果</p>
            <p className={style["minnerDitail"]}>{minnerDitail}</p>
          </div>
        }
        {
          note !== null &&
          <div className={style["item"]}>
            <p className={style["head"]}>ご注意事項</p>
            <p className={style["note"]}>{note}</p>
          </div>
        }
      </div>
    </>
  );
}

const StatusTag = ({ newTag, applicationDeadline }) => {
  if (applicationDeadline) {
    return <span className={style["deadline-tag"]}>交換期間外</span>;
  }

  if (newTag) {
    return <span className={style["new-tag"]}>NEW</span>;
  }

  return null;
}

const ClassficationTag = ({ classfication }) => {
  if (classfication === 1) {
    return <span className={style["immediate-tag"]}>もれなく</span>;
  }

  if (classfication === 2) {
    return <span className={style["lottery-tag"]}>抽選</span>;
  }

  if (classfication === 3) {
    return <span className={style["donation-tag"]}>寄付</span>;
  }
  if (classfication === 4) {
    return <span className={style["fcfs-tag"]}>先着</span>;
  }

  return null;
}
