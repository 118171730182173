import { useState } from "react";
import style from "../style/main.module.css";
import arrowIcon from "../image/icon_year_select.svg"
import overIcon from "../image/icon_over.svg";

export const Co2Table = ({
  year,
  co2Performance,
  co2Target,
  pointList,
  evaluationStatusIconList
}) => {

  const [isTableOpen, setIsTableOpen] = useState(false);

  const toggleTable = () => {
    setIsTableOpen((isOpen) => {
      return !isOpen;
    });
  }

  return (
    <div className={style["table-wrap"]}>
      <h3
        className={style["table-heading"]}
        onClick={() => {
          toggleTable();
        }}
      >
        詳しく見る
        <img
          src={arrowIcon}
          alt="arrow"
          className={`${style["table-heading-arrow"]} ${isTableOpen && style["open"]}`}
        />
      </h3>
      <div className={`${style["table-content"]} ${isTableOpen && style["open"]}`}>
        <div className={style["row"]}>
          <span>年月</span>
          <span>想定CO&#8322;排出量</span>
          <span>想定CO&#8322;削減量</span>
          <span>獲得ポイント</span>
        </div>
        <List
          year={year}
          co2Performance={co2Performance}
          co2Target={co2Target}
          pointList={pointList}
          evaluationStatusIconList={evaluationStatusIconList}
        />
      </div>
    </div>
  );
}

const List = ({
  year,
  co2Performance,
  co2Target,
  pointList,
  evaluationStatusIconList
}) => {
  const list = [];

  for (let index = 0; index <= 11; index++) {
    list.push(
      <div
        className={`${style["row"]} ${style["content"]}`}
        key={index}
      >
        <span>
          {index > 8 ? year + 1 : year}
          /
          {index > 8 ? index - 8 : index + 4}
        </span>
        <CellCo2Output
          co2Target={co2Target?.[index]}
          co2Performance={co2Performance?.[index]}
        />
        <CellCo2Reduction
          co2Target={co2Target?.[index]}
          co2Performance={co2Performance?.[index]}
        />
        <CellPoint
          point={pointList?.[index]}
          evaluationStatusIcon={evaluationStatusIconList?.[index]}
        />
      </div>
    );
  }

  return <>{list}</>;
}

const CellCo2Output = ({
  co2Target = null,
  co2Performance = null
}) => {

  return (
    <span>
      <span className={style["cell-flex"]}>
        <span className={style["text-performance-label"]}>想定値</span>
        {
          co2Performance !== null && <span className={style["text-performance"]}>{co2Performance.toLocaleString("ja-JP")}<span className={style["unit"]}>kg</span></span>
        }
      </span>
      <span className={`${style["text-target"]} ${style["cell-flex"]}`}>
        <span className={style["label"]}>標準値</span>
        {
          co2Target !== null && <span>{co2Target.toLocaleString("ja-JP")}kg</span>
        }
      </span>
    </span>
  );
}

const CellCo2Reduction = ({
  co2Target = null,
  co2Performance = null
}) => {

  if (co2Target === null || co2Performance === null) {
    return <span></span>;
  }

  const co2Reduction = co2Performance - co2Target;

  return (
    <span className={style["cell-flex"]}>
      <span className={style["co2-reduction-icon-wrap"]}>
        {
          co2Reduction > 0 && <img src={overIcon} />
        }
      </span>
      <span className={style["text-output"]}>
        {Math.abs(co2Reduction).toLocaleString("ja-JP")}<span className={style["unit"]}>kg</span>
      </span>
    </span>
  );
}

const CellPoint = ({
  point = null,
  evaluationStatusIcon = null
}) => {

  if (point === null) {
    return <span></span>;
  }

  return (
    <span className={style["cell-flex"]}>
      <img src={evaluationStatusIcon} />
      <span className={style["text-point"]}>
        {point.toLocaleString("ja-JP")}<span className={style["unit"]}>pt</span>
      </span>
    </span>
  );
}
