import style from "../style/style.module.css";

export const ButtonArea = ({ isRequiredValid, openConfirmPopup }) => {

  if (isRequiredValid) {
    return (
      <div className={style["buttonarea"]}>
        <a
          className={style["button"]}
          onClick={() => {
            openConfirmPopup();
          }}
        >送信</a>
      </div>
    );
  }

  return (
    <div className={style["buttonarea"]}>
      <a
        className={style["button-not-click"]}
      >送信</a>
    </div>
  )
}
