import { Card } from "./Card";
import style from "../style/list.module.css";

export const List = ({ prizeList }) => {
  return (
    <div className={style["prize-list"]}>
      <div className={style["prize-list-wrap"]}>
        {
          prizeList.map((prize) => (
            <Card
              prize={prize}
              key={prize.id}
            />
          ))
        }
      </div>
    </div>
  );
}