export const getMaxScaleValue = (defaultMaxScale, data1, data2) => {
  const scaleCalculationData = [
    ...data1.filter(value => value !== null),
    ...data2.filter(value => value !== null)
  ];
  const maxDataValue = Math.max(...scaleCalculationData);
  const maxScaleValue = maxDataValue === -Infinity || maxDataValue === 0
    ? defaultMaxScale
    : Math.round(maxDataValue / 0.75 / 4) * 4;

  return maxScaleValue;
}
