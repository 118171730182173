import {
  InputTitle,
  InputText,
} from "./FormParts";
import { Link } from "react-router-dom";
import style from "../style/style.module.css";

export const Body = ({
  formData,
  setValue,
  validError
}) => {
  return (
    <div className={style["section-body"]}>
      <p className={style["text-center"]}>ポイント付与コードをお持ちの方は、<br />こちらにご入力ください。</p>
      <InputTitle
        required
      />
      <div className={style["input-pointAssignmentCode-wrap"]}>
        <InputText
          defaultValue={formData?.pointAssignmentCode}
          name="pointAssignmentCode"
          setValue={setValue}
          fullWidth
          validError={validError}
          maxlength={16}
        />
      </div>
      <p className={style["text-center"]}>
        獲得ポイントの反映は翌月末を予定しています。<br />
        それ以上経っても反映されない場合は<br />
        <Link to="/secretariatContact" className={style["link-underline"]}>
          事務局へお問い合わせ
        </Link>
        ください。
      </p>
    </div>
  );
}
