import style from "../style/applied.module.css";

export const Applied = ({ appliedUnit }) => {
  if (appliedUnit === null || appliedUnit === 0) {
    return null;
  }

  return (
    <div className={style["applied"]}>
      <div className={style["applied-unit-wrap"]}>
        <span className={style["bold"]}>交換済口数</span>
        <span className={style["unit"]}>
          <span className={style["applied-unit"]}>{appliedUnit.toLocaleString("ja-JP")}</span>
          口
        </span>
      </div>
      <div className={style["line"]}></div>
    </div>
  );
}
