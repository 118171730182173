import yearSelect from "../image/icon_year_select.svg";
import style from "../style/list.module.css";

export const List = ({
  list,
  year,
  maxYear,
  minYear,
  incrementYear,
  decrementYear
}) => {
  return (
    <div className={style["list-area"]}>
      <div className={style["year-select-wrap"]}>
        <a
          className={style["year-select-left"]}
          onClick={() => { decrementYear() }}
        >
          {
            year > minYear && <img src={yearSelect} alt="arrow" />
          }
        </a>
        <span>{year}年度</span>
        <a
          className={style["year-select-right"]}
          onClick={() => { incrementYear() }}
        >
          {
            year < maxYear && <img src={yearSelect} alt="arrow" />
          }
        </a>
      </div>
      {
        list.map((item, index) => (
          <div
            className={`${style["list"]} ${item.point >= 0 && style["add"]}`}
            key={index}
          >
            <div className={style["list-left"]}>
              <p className={style["list-date"]}>{item.date}</p>
              <p className={style["list-title"]}>{item.title}</p>
              <p className={style["list-detail"]}>{item.detail}</p>
            </div>
            <div>
              {
                item.point >= 0
                  ? <p className={style["list-point"]}>
                    +{item.point.toLocaleString("ja-JP")}<span className={style["list-point-unit"]}>pt</span>
                  </p>
                  : <p className={style["list-point"]}>
                    {item.point.toLocaleString("ja-JP")}<span className={style["list-point-unit"]}>pt</span>
                  </p>
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}