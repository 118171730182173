import { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { graphLegend } from '../../../utility/mapping.js';
import { getMaxScaleValue } from '../utility/getMaxScaleValue.js';
import { getGraphData, getGraphOptions } from "../utility/graphConfig";
import arrowIcon from "../image/icon_year_select.svg"
import style from "../style/main.module.css";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
);

export const Graph = ({
  type,
  energyUnit,
  year,
  maxYear,
  minYear,
  incrementYear,
  decrementYear,
  targetData,
  performanceData,
  defaultMaxScale = 100,
  graphColor,
  waiting = false
}) => {

  // グラフの目盛り
  const maxScaleValue = getMaxScaleValue(defaultMaxScale, targetData, performanceData);
  const scaleStep = maxScaleValue / 4;

  // グラフデータ
  const graphData = getGraphData(performanceData, targetData, graphColor);

  // グラフの設定
  const options = getGraphOptions(maxScaleValue, scaleStep, energyUnit);

  /**
   * グラフの枠線を描画するプラグイン
   */
  const customBorder = {
    id: 'customBorder',
    beforeDatasetsDraw(chart) {
      const { ctx, chartArea: { top, bottom, left, right } } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = "rgba(0,0,0,0.4)";
      ctx.moveTo(left - 1, top - 1);
      ctx.lineTo(right + 1, top - 1);
      ctx.lineTo(right + 1, bottom + 1);
      ctx.lineTo(left - 1, bottom + 1);
      ctx.closePath();
      ctx.stroke();
    }
  }

  /**
   * グラフのグリッド線を描画するプラグイン
   */
  const customGridBorder = {
    id: 'customGridBorder',
    beforeDatasetsDraw(chart) {
      const { ctx, chartArea: { left, right }, scales: { y } } = chart;
      for (let i = 1; i < y._gridLineItems.length - 1; i++) {
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgba(0,0,0,0.4)";
        ctx.moveTo(left, y._gridLineItems[i].ty1);
        ctx.lineTo(right, y._gridLineItems[i].ty1);
        ctx.closePath();
        ctx.stroke();
        ctx.restore();
      }
    }
  }

  /**
   * エネルギーデータ連携前の案内文を描画するプラグイン
   */
  const waitingTextRef = useRef();
  waitingTextRef.current = waiting ? "実績反映までお待ちください" : "";
  const waitingText = {
    id: 'waitingText',
    beforeDatasetsDraw(chart) {
      const { ctx, chartArea: { left, right, top, bottom, width } } = chart;
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      ctx.font = `${Math.round(width / 20)}px "Noto Sans JP", sans-serif`;
      ctx.fillStyle = "#666666";
      ctx.fillText(waitingTextRef.current, (left + right) / 2, (top + bottom) / 2 / 1.1);
    }
  }

  // プラグインの設定
  const plugins = [customBorder, customGridBorder, waitingText];

  // 凡例画像の表示判定（目標値が存在するか）
  const hasTargetData = targetData.length > 0;

  return (
    <div className={style["graph-wrap"]}>
      <div className={style["year-select"]}>
        <a className={style["year-select-prev"]}>
          {
            year > minYear &&
            <img
              src={arrowIcon}
              alt="arrow"
              onClick={() => {
                decrementYear()
              }}
            />
          }
        </a>
        <span>{year}年度</span>
        <a className={style["year-select-next"]}>
          {
            year < maxYear &&
            <img
              src={arrowIcon}
              alt="arrow"
              onClick={() => {
                incrementYear()
              }}
            />
          }
        </a>
      </div>
      <Chart
        type="bar"
        data={graphData}
        options={options}
        plugins={plugins}
        id="chart-key"
      />
      <img
        src={hasTargetData ? graphLegend[type]["default"] : graphLegend[type]["noTarget"]}
        className={style["graph-legend"]}
        alt="legend"
      />
    </div>
  );
}